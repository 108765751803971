<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn depressed color="primary" class="ml-4" @click="prepareSession"
          >Rozpocznij naukę</v-btn
        >

        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn v-bind="attrs" v-on="on" class="ml-2" depressed>
              Eksportuj do...
            </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="exportQuizlet">
              <v-list-item-title>Quizlet</v-list-item-title>
            </v-list-item>

            <v-list-item link @click="exportFiszkoteka">
              <v-list-item-title>Fiszkoteka</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-card class="mt-4">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Wyszukaj słowa"
              single-line
              hide-details
              outlined
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="flashcards"
            :search="search"
            hide-default-footer
            no-results-text="Brak wyników."
            no-data-text="Lista jest pusta."
            :disable-pagination="true"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- EXPORT DIALOGS -->
    <v-dialog v-model="exportQuizletDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Eksportuj do Quizlet
        </v-card-title>

        <v-card-text class="mt-6">
          <ol>
            <li>
              Wejdź na stronę
              <a href="https://quizlet.com/create-set" target="_blank">tworzenia zestawu Quizlet</a>.
            </li>
            <li>Wybierz <i>+ Importuj z Word, Excel, Google Docs itp.</i></li>
            <li>Skopiuj tekst z pola poniżej i wklej go do <i>Quizlet'a</i></li>
            <li>Wybierz <i>Importuj</i>, a następnie stwórz zestaw.</li>
          </ol>
          <div class="mt-4">
            <v-btn color="primary" depressed class="mb-3" @click="copyQuizlet"
              >Skopiuj</v-btn
            >
            <v-textarea
              ref="quizletTextarea"
              outlined
              name="input-7-4"
              readonly
              :value="exportQuizletContent"
            ></v-textarea>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="exportFiszkotekaDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Eksportuj do Fiszkoteka
        </v-card-title>

        <v-card-text class="mt-6">
          <ol>
            <li>
              Wejdź na stronę
              <a href="https://fiszkoteka.pl/stworz-fiszki" target="_blank">tworzenia zestawu w Fiszkotece</a>.
            </li>
            <li>Wybierz <i>Importuj z listy słówek</i></li>
            <li>Skopiuj tekst z pola poniżej i wklej go do <i>Fiszkoteki</i></li>
            <li>Wybierz <i>Importuj fiszki</i>, a następnie stwórz zestaw.</li>
          </ol>
          <div class="mt-4">
            <v-btn color="primary" depressed class="mb-3" @click="copyFiszkoteka"
              >Skopiuj</v-btn
            >
            <v-textarea
              ref="fiszkotekaTextarea"
              outlined
              name="input-7-4"
              readonly
              :value="exportFiszkotekaContent"
            ></v-textarea>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import db from "@/firebase/init";
import { mapMutations } from "vuex";

export default {
  props: ["id"],
  mounted() {
    if (this.id == undefined) {
      this.$router.replace({ name: "Dashboard" });
    }

    // Let's get set data

    db.collection("flashcards")
      .doc(this.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // everything is ok
          this.flashcards = doc.data().flashcards;
        } else {
          alert("Niestety, taki zestaw nie istnieje.");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  },

  data() {
    return {
      search: "",
      headers: [
        { text: "Polski", value: "front" },
        { text: "Angielski", value: "back" },
      ],
      flashcards: [],
      exportQuizletDialog: false,
      exportQuizletContent: "",
      exportFiszkotekaDialog: false,
      exportFiszkotekaContent: "",
    };
  },

  methods: {
    ...mapMutations(["setFlashcards"]),
    prepareSession() {
      this.setFlashcards(this.flashcards);
      this.$router.replace({ name: "Flashcards", id: this.id });
    },
    // exports
    exportQuizlet() {
      let str = "";

      this.flashcards.forEach((flashcard) => {
        let tempStr = flashcard.front + "\t" + flashcard.back + "\n";

        str += tempStr;
      });

      this.exportQuizletContent = str;
      this.exportQuizletDialog = true;
    },
    copyQuizlet() {
      this.$refs.quizletTextarea.$refs.input.select();
      document.execCommand("copy");
    },
    exportFiszkoteka() {
      let str = "";

      this.flashcards.forEach((flashcard) => {
        let tempStr = flashcard.front + "," + flashcard.back + "\n";

        str += tempStr;
      });

      this.exportFiszkotekaContent = str;
      this.exportFiszkotekaDialog = true;
    },
    copyFiszkoteka() {
      this.$refs.fiszkotekaTextarea.$refs.input.select();
      document.execCommand("copy");
    },
  },
};
</script>

<style>
</style>